import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'

export const useMapboxStreetStyleId = (localeCode: AvailableLocaleCodes) => {
  const { ES, FR, EN, IT, PT, DE } = LOCALE_CODES
  const styleStreetMap: Record<string, string> = {
    [DE]: 'clhhp7jvy01ft01pgb0b2cu2v',
    [EN]: 'clhhhad2x01d501pr5swjaigf',
    [ES]: 'clhhox2ox01gp01pg3svg8qyc',
    [FR]: 'clhhowbpk01fp01pgca4h915e',
    [IT]: 'clhhp5rpy01dw01quf69f2c0y',
  }

  if (localeCode === PT) {
    return 'clhhp6mdj01e001quh5sc7q7i'
  }
  return styleStreetMap[getTopLevelLocale(localeCode)] || styleStreetMap[EN]
}
