<script setup lang="ts">
import { useResizeObserver, useDebounceFn } from '@vueuse/core'

interface Props {
  lng: number
  lat: number
  zoom?: number
  aspect?: string
  withMarker?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  zoom: 13,
  aspect: 'aspect-square sm:aspect-video',
  withMarker: false,
})

const { localeProperties: { yscCode } } = useLocaleProperties()
const { public: { mapbox: { key } } } = useRuntimeConfig()

const tileSrc = ref()
const { pixelRatio: devicePixelRatio } = useDevicePixelRatio()
const useStadiaMaps = useFeatureFlag('useStadiaMaps')
const setTileSrc = ({ width, height }: { width: number, height: number }) => {
  if (!width || !height) {
    return
  }

  const lat = props.lat.toFixed(6)
  const lng = props.lng.toFixed(6)

  if (useStadiaMaps) {
    const MAX_DIMENSION = 1280
    const url = new URL(`https://tiles-eu.stadiamaps.com/static/outdoors.png`)

    url.searchParams.append('center', [lat, lng].join(','))
    url.searchParams.append('zoom', props.zoom.toString())
    url.searchParams.append('size', `${Math.min(Math.floor(width), MAX_DIMENSION)}x${Math.min(Math.floor(height), MAX_DIMENSION)}${devicePixelRatio.value > 1 ? '@2x' : ''}`)
    if (props.withMarker) {
      url.searchParams.append('markers', `${[lat, lng].join(',')},outdoors,d92465`)
    }

    tileSrc.value = url.href
  }
  else {
    const overlaySegment = props.withMarker ? `/pin-s-circle+d92465(${[lng, lat].join(',')})` : ''
    const coordsSegment = `/${[lng, lat, props.zoom].join(',')}`
    // mapbox doesn't allow request > 1280px
    const MAX_DIMENSION = 1280
    const dimensionsSegment = `/${Math.min(Math.floor(width), MAX_DIMENSION)}x${Math.min(Math.floor(height), MAX_DIMENSION)}${devicePixelRatio.value > 1 ? '@2x' : ''}`
    const url = new URL(
      `/styles/v1/yescapa/${useMapboxStreetStyleId(yscCode)}/static${overlaySegment}${coordsSegment}${dimensionsSegment}`,
      'https://api.mapbox.com',
    )
    url.searchParams.append('logo', 'false')
    url.searchParams.append('access_token', key)

    tileSrc.value = url.href
  }
}

const el = ref<HTMLDivElement | null>(null)

useResizeObserver(el, useDebounceFn((entries) => {
  const { width, height } = entries[0].contentRect
  setTileSrc({ width, height })
}, 1000))

onMounted(() => {
  if (!el.value) {
    return
  }

  const { width, height } = el.value.getBoundingClientRect()
  setTileSrc({ width, height })
})
</script>

<template>
  <div
    ref="el"
    class="bg-gray-100"
    :class="aspect"
  >
    <img
      :src="tileSrc"
      loading="lazy"
      alt="-"
    >
  </div>
</template>
